import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/system';

const FormWrapper = styled(Box)(({ theme }) => {
  const {
    headerHeight,
    breakpoints,
    palette,
    typography,
    spacing,
    shadows,
  } = theme;
  return css`
    margin-top: ${headerHeight}px;
    width: 100%;

    ${breakpoints.up('md')} {
      display: grid;
      grid-template-areas: 'form illus';
      grid-template-columns: 488px 1fr;
    }

    .catchphrase {
      color: ${palette.info.dark};
      font-size: ${typography.body1.fontSize};
      margin-bottom: ${spacing(4)};
    }

    .side-panel {
      background: ${palette.common.white};
      padding: ${spacing(4, 3, 2)};
      min-height: calc(100vh - ${headerHeight}px);
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      position: relative;
      ${breakpoints.up('sm')} {
        padding: ${spacing(6, 6, 6)};
        grid-area: form;
        box-shadow: ${shadows[2]};
        z-index: 2;
      }

      & > * {
        ${breakpoints.up('md')} {
          /* Make sure the inherited height of all the direct child blocks will be respected by default */
          flex-grow: 0;
          flex-shrink: 0;
        }
      }

      .form-wrapper {
        ${breakpoints.up('md')} {
          /* Allow the block to grow vertically to be able to center its content */
          flex-grow: 1;
          /* Center vertically its content */
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .disclaimers {
        margin-top: ${spacing(4)};
      }

      .title {
        text-align: center;
        margin-bottom: ${spacing(3)};
        ${breakpoints.up('md')} {
          text-align: left;
        }
      }

      .catchphrase {
        margin-bottom: ${spacing(3)};
        font-weight: ${typography.body1.fontWeight};
      }

      form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .intl-tel-input {
          .country-list {
            ${breakpoints.down('sm')} {
              width: 100%;
            }
          }
        }

        > button[type='submit'] {
          margin: ${spacing(4, 0, 0, 0)};
        }
      }

      .conditions {
        width: 100%;
        grid-template-areas: 'checkbox . label';
        grid-template-columns: 24px 5px 1fr;
        margin: 12px 0 0 0;
        display: grid;

        .checkbox {
          grid-area: checkbox;
        }

        .conditions-link {
          padding-top: 5px; // Make sure that label is perfectly aligned with the checkbox input
        }

        a {
          grid-area: label;
          align-self: center;
          text-decoration: underline;
          color: ${palette.info.dark};
          cursor: pointer;
          &:hover {
            color: ${palette.primary.main};
          }
        }
      }

      .aide-support {
        margin-top: ${theme.spacing(1)};
      }

      .note,
      .instructions {
        margin-top: ${theme.spacing(2)};
        font-size: ${typography.caption.fontSize};
      }
    }

    .illustration {
      transform: scaleX(-1);
      ${breakpoints.down('md')} {
        display: none !important;
        width: 0px;
      }
      grid-area: illus;

      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${palette.background.secondary};
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-origin: content-box;
      padding: 8vw;
    }

    &.flow-rdv {
      display: flex;
      min-height: calc(100vh - ${headerHeight}px);
      margin: ${headerHeight}px auto 0;
      position: relative;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      max-width: 830px;
      background: ${palette.background.secondary};
      padding: ${spacing(2)} ${spacing(2)} ${spacing(2)};
      ${breakpoints.up('sm')} {
        padding: ${spacing(3)} ${spacing(3)} ${spacing(3)};
      }

      .side-panel {
        box-shadow: none;
        padding: ${spacing(6, 3)};
        margin: 0 auto;
        border-radius: 0 0 8px 8px;
        min-height: unset;
        flex: 1;
        ${breakpoints.up('sm')} {
          padding: ${spacing(6, 12)};
        }

        .form-wrapper {
          ${breakpoints.up('md')} {
            /* Make sure the inherited height of the block will be respected */
            flex-grow: 0;
            display: block;
          }
        }

        .title,
        .catchphrase {
          text-align: center;
        }
      }

      .illustration {
        display: none;
      }

      form {
        align-items: initial;
      }

      .appointment-info-bar {
        width: 100%;
        border-radius: 8px 8px 0 0;
      }
    }
  `;
});

export default FormWrapper;
