import React from 'react';
import { DialogContent } from '@mui/material';
import Link from '../../atoms/Link/Link';
import { useTranslation } from '@/src/i18n';
import Text from '../../atoms/Text/Text';
import { APP_ROUTES } from '@/src/constants';
import Dialog from '@/components/molecules/Dialog/Dialog';

const CnilCovidDisclaimer = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleDialog = () => setOpen(prevState => !prevState);

  return (
    <div className="cnil-disclaimer small">
      <Text component="p" variant="paragraph">
        {t('mentions-info-vaccination-summary')}
        <a onClick={handleDialog}>{t('more-info-vaccination')}</a>
      </Text>
      <Dialog
        open={open}
        onClose={handleDialog}
        className="cnil-disclaimer"
        title={t('mentions-info-vaccination-title')}
      >
        <DialogContent>
          <Text component="p">
            {t('mentions-info-vaccination-details')}{' '}
            <a href="mailto:DPO@cegedim-sante.com">
              &nbsp;DPO@cegedim-sante.com
            </a>
          </Text>
          <Text component="p">
            {t('more-information-contact')}&nbsp;
            <Link href={APP_ROUTES.POLICIES.COVID} passHref>
              <a>{t('mentions-vaccination-link')}</a>
            </Link>
          </Text>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default CnilCovidDisclaimer;
