import React from 'react';
import clsx from 'clsx';

import { IconStarFilled } from '@maiia/design-system';
import { useCurrentUser } from '@docavenue/components';
import { PatientUser } from '../WithAuth/types';

type Props = {
  centerId: string | undefined;
  profileId?: string;
};

const FlagCenter = ({ centerId, profileId }: Props) => {
  const profiles =
    useCurrentUser<PatientUser>()?.userPatientInformation
      ?.userPatientProfiles ?? [];
  const isFavorite = profileId
    ? profiles
        .find(profile => profile.id === profileId)
        ?.favoriteCenters?.find(center => center.centerId === centerId)
        ?.centerId
    : profiles
        .reduce(
          (acc, val) => [...acc, ...(val?.favoriteCenters ?? [])],
          [] as { centerId?: string }[], // todo types - 👈 this is the correct type according to swagger (centerId may be undefined) - those undefined `centerId` should be filtered before the find below
        )
        .find(center => center.centerId === centerId)?.centerId;

  return isFavorite ? (
    <IconStarFilled className={clsx('care-status', 'favorite')} />
  ) : null;
};

export default FlagCenter;
