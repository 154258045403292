import * as React from 'react';
import { Box, NoSsr, Typography } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { useTranslation } from '@/src/i18n';
import useLinkAccountInfo from '@/src/hooks/useLinkAccountInfo';
import useSSOUserData from '@/src/hooks/useSSOUserData';
import { APP_ROUTES } from '@/src/constants';

const StyledSection = styled(Box)(({ theme }) => {
  return css`
    background-color: ${theme.palette.background.infoLightBlue};
    padding: ${theme.spacing(2)};
    color: ${theme.palette.text.link};
    text-align: center;
    margin-bottom: ${theme.spacing(3)};
    border-radius: ${theme.spacing(2)};
  `;
});

const LinkAccountSection = () => {
  const { t } = useTranslation();
  const { data } = useLinkAccountInfo();
  return (
    <StyledSection>
      <Typography variant="h5" fontWeight={600}>
        {t('link_account_section_title')}
      </Typography>
      <Typography variant="body1">
        <Typography variant="body1" component="span" fontWeight={600}>
          {/* the patient has an appointment (isAppointmentId:true) | recieved a document (isAppointmentId:false) <= This is not true, we also have case link without appointment or document  */}
          {data?.appointmentId
            ? t('link_account_section_span')
            : t('link_account_section_span_for_document')}
        </Typography>
        {t('link_account_section_paragraph')}
      </Typography>
    </StyledSection>
  );
};

const SSOSection = () => {
  const { t } = useTranslation();
  const router = useRouter();

  let notice: string | undefined;
  if (router.pathname === APP_ROUTES.LOGIN) {
    notice = t('sso-notice-login');
  } else if (router.pathname === APP_ROUTES.REGISTER) {
    notice = t('sso-notice-register');
  } else {
    return null;
  }

  return (
    <StyledSection>
      <Typography variant="body1" component="span" fontWeight={600}>
        {notice}
      </Typography>
    </StyledSection>
  );
};

const HeadingSectionComponent = () => {
  const { data: ssoData } = useSSOUserData();
  const { data: linkAccountData } = useLinkAccountInfo();
  if (ssoData?.token) return <SSOSection />;
  if (linkAccountData?.token) return <LinkAccountSection />;
  return null;
};

const HeadingSection = () => (
  <NoSsr>
    <HeadingSectionComponent />
  </NoSsr>
);

export default HeadingSection;
