// @flow

import React from 'react';
import clsx from 'clsx';

import { useCurrentUser } from '@docavenue/components';
import { isEmpty } from 'lodash';
import { IconHeartFilled, IconStarFilled } from '@maiia/design-system';
import {
  getFavoritePractionerIds,
  getReferringPractitionerIds,
} from '../../../src/utils';
import { useTranslation } from '@/src/i18n';

type Props = {
  practitionerId?: string;
  profileId?: string;
};

const getPreferencesPractitioner = (practitioners, practitionerId) => {
  if (practitioners?.referring?.includes(practitionerId)) return 'heart';
  // TODO: need confirm, favorite also need check by both practitionerId and centerId
  if (practitioners?.favorites?.includes(practitionerId)) return 'starfull';
  return '';
};

const IconHeart = props => <IconHeartFilled {...props} />;

const IconStarFull = props => <IconStarFilled {...props} />;

// if your flag practitioner concerne a current patient, then provide in props to FlagPractitioner then object current patient
const FlagPractitioner = ({ practitionerId, profileId }: Props) => {
  const user = useCurrentUser();
  const { t } = useTranslation();
  if (isEmpty(user)) return null;
  const profiles = user?.userPatientInformation?.userPatientProfiles ?? [];
  const profile =
    typeof profileId === 'undefined'
      ? null
      : profiles.find(({ id }) => id === profileId);
  if (profileId && !profile) {
    return null;
  }
  const referringPractitioner = profile?.referringPractitioner?.practitionerId
    ? [profile?.referringPractitioner?.practitionerId]
    : [];
  const practitioners = {
    favorites: profile
      ? profile?.favoritePractitioners?.map(({ practitionerId: id }) => id)
      : getFavoritePractionerIds(profiles),
    referring: profile
      ? referringPractitioner
      : getReferringPractitionerIds(profiles),
  };

  const preference = getPreferencesPractitioner(practitioners, practitionerId);
  if (isEmpty(preference)) {
    return null;
  }
  const IconComponent = preference === 'heart' ? IconHeart : IconStarFull;
  let iconClass = '';
  let ariaLabel = '';
  if (preference === 'heart') {
    iconClass = 'primary';
    ariaLabel = t('my_attending_physician');
  } else if (preference === 'starfull') {
    iconClass = 'favorite';
    ariaLabel = t('in_favorites');
  }

  return (
    <IconComponent
      className={clsx('care-status', iconClass)}
      aria-label={ariaLabel}
    />
  );
};

export default FlagPractitioner;
